import { Fragment, useContext, useEffect, useState } from 'react';

import { T } from '@/components/T';
import UBButton from '@/components/UBButton';
import { NO_BREAK_SPACE } from '@/constants/space';
import { useTranslate } from '@/hooks/useTranslate';
import { useTracking } from '@/providers/TrackingProvider';
import { UserStateContext } from '@/providers/UserStateProvider';
import { parseEmoji } from '@/utils/helpers';

import type { Category } from '@/types/categories';

interface UBCategoriesProps {
  heading?: string;
  categories: Category[];
  type?: string;
  onlyNewUsers?: boolean;
}

const UBCategories = ({
  heading,
  categories,
  type,
  onlyNewUsers,
}: UBCategoriesProps) => {
  const { userState } = useContext(UserStateContext);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [hideCategories, setHideCategories] = useState<boolean>(false);
  const { initializeTracking } = useTracking();
  const { t } = useTranslate('musicTags');

  useEffect(() => {
    parseEmoji();
    setHideCategories(!!onlyNewUsers && !!userState.hasLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hideCategories) {
    return null;
  }

  return (
    <div className={`ub-categories ub-categories-${type}`}>
      <h3>
        <T ns="common">{heading ?? NO_BREAK_SPACE}</T>
      </h3>
      <div className="ub-categories-desktop">
        {type === 'music' &&
          categories.map((category) => {
            const categoryName = t(category.name);

            return (
              <Fragment key={category.name}>
                <hr />
                <h4>{categoryName}</h4>
                {category.tags.map((tag) => {
                  return (
                    <UBButton
                      href={tag.link}
                      key={tag.name}
                      variant="primary"
                      label={t(tag.name)}
                      borderRadius="full"
                      width="min-content"
                      padding="0 0.5rem"
                      size="small"
                      onClick={() => {
                        void initializeTracking({
                          assetType: 'music',
                          endSlug: tag.link,
                          content: tag.name,
                          contentType: 'Tag',
                          access: 'Category Tags',
                        });
                      }}
                    />
                  );
                })}
              </Fragment>
            );
          })}
        {type === 'sfx' && (
          <div className="ub-categories-desktop-grouped">
            {categories.map((category) => (
              <div
                className="ub-categories-desktop-grouped-item"
                key={category.name}
              >
                <hr />
                <h4>{category.name}</h4>
                {category.tags.map((tag) => {
                  return (
                    <UBButton
                      key={tag.name}
                      href={tag.link}
                      variant="primary"
                      label={t(tag.name)}
                      width="min-content"
                      padding="0 0.5rem"
                      onClick={() => {
                        void initializeTracking({
                          assetType: 'sfx',
                          endSlug: tag.link,
                          content: tag.name,
                          contentType: 'Tag',
                          access: 'Category Tags',
                        });
                      }}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="ub-categories-mobile">
        <div className="ub-categories-mobile-header">
          {categories.map((category) => {
            return (
              <UBButton
                size="large"
                variant={
                  activeCategory === category.id ? 'uppbeat' : 'secondary'
                }
                label={t(category.name)}
                padding="0 0.75rem"
                key={category.name}
                onClick={() => setActiveCategory(category.id)}
              />
            );
          })}
        </div>
        <div className="ub-categories-mobile-list">
          {categories[activeCategory].tags.map((tag) => {
            return (
              <UBButton
                href={tag.link}
                key={tag.name}
                size="small"
                variant="transparent"
                label={t(tag.name)}
                borderRadius="full"
                padding="0 0.75rem"
                onClick={() => {
                  void initializeTracking({
                    endSlug: tag.link,
                    content: tag.name,
                    contentType: 'Tag',
                    access: 'Category Tags',
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UBCategories;
