import type { Category } from '@/types/categories';

export const musicTagCategories: Category[] = [
  {
    id: 0,
    name: 'Moods',
    tags: [
      { name: 'Aesthetic', link: '/music/tag/aesthetic' },
      { name: 'Calm', link: '/browse/music/calm' },
      { name: 'Chill', link: '/music/mood/chill' },
      { name: 'Creepy', link: '/music/mood/creepy' },
      { name: 'Dark', link: '/browse/music/dark' },
      { name: 'Dramatic', link: '/browse/music/dramatic' },
      { name: 'Emotional', link: '/music/mood/emotional' },
      { name: 'Energetic', link: '/music/mood/energetic' },
      { name: 'Fast', link: '/music/mood/fast' },
      { name: 'Funny', link: '/music/mood/funny' },
      { name: 'Happy', link: '/browse/music/happy' },
      { name: 'Inspirational', link: '/music/mood/inspirational' },
      { name: 'Intense', link: '/music/mood/intense' },
      { name: 'Love', link: '/music/mood/love' },
      { name: 'Motivational', link: '/music/mood/motivational' },
      { name: 'Patriotic', link: '/music/mood/patriotic' },
      { name: 'Relaxing', link: '/music/mood/relaxing' },
      { name: 'Romantic', link: '/music/mood/romantic' },
      { name: 'Sad', link: '/browse/music/sad' },
      { name: 'Scary', link: '/browse/music/scary' },
      { name: 'Slow', link: '/music/mood/slow' },
      { name: 'Sneaky', link: '/music/mood/sneaky' },
      { name: 'Soft', link: '/music/tag/soft' },
      { name: 'Stylish', link: '/music/tag/stylish' },
      { name: 'Suspense', link: '/music/mood/suspense' },
      { name: 'Upbeat', link: '/music/mood/upbeat' },
      { name: 'Uplifting', link: '/browse/music/uplifting' },
    ],
  },
  {
    id: 1,
    name: 'Instruments',
    tags: [
      { name: 'Acoustic', link: '/browse/music/acoustic' },
      { name: 'Bass', link: '/music/tag/bass' },
      { name: 'Drum', link: '/music/instrument/drum' },
      { name: 'Flute', link: '/music/instrument/flute' },
      { name: 'Guitar', link: '/music/instrument/guitar' },
      { name: 'Piano', link: '/music/instrument/piano' },
      { name: 'Retro synth', link: '/music/instrument/retro-synth' },
      { name: 'Synth', link: '/music/instrument/synth' },
      { name: 'Ukulele', link: '/music/instrument/ukulele' },
      { name: 'Violin', link: '/music/instrument/violin' },
    ],
  },
  {
    id: 2,
    name: 'Occasions',
    tags: [
      { name: 'Christmas', link: '/music/theme/christmas' },
      { name: 'Halloween', link: '/music/theme/halloween' },
      { name: 'Wedding', link: '/music/theme/wedding' },
    ],
  },
  {
    id: 3,
    name: 'Genres',
    tags: [
      { name: '8 bit', link: '/music/genre/8-bit' },
      { name: 'Ambient', link: '/browse/music/ambient' },
      { name: 'Beats', link: '/music/genre/beats' },
      { name: 'Chillhop', link: '/browse/music/chillhop' },
      { name: 'Classical', link: '/music/genre/classical' },
      { name: 'Cinematic', link: '/music/genre/cinematic' },
      { name: 'Country', link: '/music/genre/country' },
      { name: 'Cyberpunk', link: '/music/genre/cyberpunk' },
      { name: 'Dance', link: '/music/genre/dance' },
      { name: 'Dubstep', link: '/music/genre/dubstep' },
      { name: 'EDM', link: '/music/genre/edm' },
      { name: 'Electronic', link: '/browse/music/electronic' },
      { name: 'Elevator', link: '/music/genre/elevator' },
      { name: 'Epic', link: '/browse/music/epic' },
      { name: 'Folk', link: '/music/genre/folk' },
      { name: 'Hip Hop', link: '/browse/music/hip-hop-beats' },
      { name: 'House', link: '/music/genre/house' },
      { name: 'Hype', link: '/music/mood/hype' },
      { name: 'Indie', link: '/browse/music/indie' },
      { name: 'Jazz', link: '/music/genre/jazz' },
      { name: 'Lofi', link: '/browse/music/lo-fi-beats' },
      { name: 'Metal', link: '/music/genre/metal' },
      { name: 'Pop', link: '/browse/music/pop' },
      { name: 'Punk', link: '/music/genre/punk' },
      { name: 'Rap', link: '/music/tag/rap' },
      { name: 'Reggae', link: '/music/genre/reggae' },
      { name: 'Rock', link: '/browse/music/rocky' },
      { name: 'Synthwave', link: '/music/genre/synthwave' },
      { name: 'Techno', link: '/music/genre/techno' },
      { name: 'Trap', link: '/browse/music/trap' },
    ],
  },
  {
    id: 4,
    name: 'Themes',
    tags: [
      { name: 'Action', link: '/music/theme/action' },
      { name: 'Battle', link: '/music/theme/battle' },
      { name: 'Business', link: '/browse/music/business' },
      { name: 'Cartoon background', link: '/music/tag/cartoon-background' },
      { name: 'Christian', link: '/music/theme/christian' },
      { name: 'Church', link: '/music/tag/church' },
      { name: 'Commercial', link: '/music/theme/commercial' },
      { name: 'Cooking', link: '/music/theme/cooking' },
      { name: 'Corporate', link: '/music/theme/corporate' },
      { name: 'Documentary', link: '/music/theme/documentary' },
      { name: 'Drone', link: '/music/tag/drone' },
      { name: 'Fantasy', link: '/music/theme/fantasy' },
      { name: 'Fitness', link: '/music/theme/fitness' },
      { name: 'Game', link: '/music/tag/game' },
      { name: 'Holiday', link: '/music/theme/holiday' },
      { name: 'Horror', link: '/music/mood/horror' },
      { name: 'Jingle', link: '/music/tag/jingle' },
      { name: 'Meditation', link: '/music/theme/meditation' },
      { name: 'Nature documentary', link: '/music/theme/nature-documentary' },
      { name: 'News', link: '/music/tag/news' },
      { name: 'Retro', link: '/music/tag/retro' },
      { name: 'Space', link: '/music/theme/space' },
      { name: 'Sports', link: '/music/theme/sports' },
      { name: 'Traditional', link: '/music/tag/traditional' },
      { name: 'Trailer', link: '/music/tag/trailer' },
      { name: 'Travel', link: '/browse/music/travel' },
      { name: 'Vlog', link: '/browse/music/vlog' },
      { name: 'Western', link: '/music/theme/western' },
      { name: 'Workout', link: '/music/theme/workout' },
      { name: 'Worship', link: '/music/tag/worship' },
      { name: 'Yoga', link: '/music/theme/yoga' },
    ],
  },
  {
    id: 5,
    name: 'Eras',
    tags: [
      { name: 'Medieval', link: '/music/tag/medieval' },
      { name: '1920s', link: '/music/tag/1920s' },
      { name: '50s', link: '/music/tag/50s' },
      { name: '60s', link: '/music/tag/60s' },
      { name: '70s', link: '/music/tag/70s' },
      { name: '80s', link: '/music/tag/80s' },
      { name: '90s', link: '/music/tag/90s' },
    ],
  },
  {
    id: 6,
    name: 'Global',
    tags: [
      { name: 'African', link: '/music/tag/african' },
      { name: 'Arabic', link: '/music/tag/arabic' },
      { name: 'Chinese', link: '/music/tag/chinese' },
      { name: 'Indian', link: '/music/tag/indian' },
      { name: 'Italian', link: '/music/tag/italian' },
      { name: 'Irish', link: '/music/tag/irish' },
      { name: 'Japanese', link: '/music/tag/japanese' },
      { name: 'Latin', link: '/music/genre/latin' },
      { name: 'Spanish', link: '/music/tag/spanish' },
    ],
  },
];
